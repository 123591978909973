// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// interfaces
interface MenuInterface {
    visibleItem?: boolean,
    lastItem?: boolean,
    currentPath?: boolean,
}

// styles - menu
export const Header = styled.ul`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 90px;
`;

export const Logo = styled.img`
    width: 160px;
    object-fit: contain;
`;

export const HeaderItemLogo = styled.a`
    padding: 30px 60px;
    color: ${colors.basic.white};
    font-size: 30px;
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    font-size: 30px;

    /* media query */
    @media (max-width: 680px) {
        font-size: 22px;
        padding: 20px 20px;
    }
`;

export const HamburgerOpen = styled.div<MenuInterface>`
    display: none;
    z-index: 4;
    cursor: pointer;

    /* media query */
    @media (max-width: 1020px) {
        position: absolute;
        display: flex;
        right: 15px;
        top: 20px;
        flex-flow: column nowrap;
    }
`;

export const OpenIcon = styled.div<MenuInterface>`
    width: 27px;
    height: 3px;
    border-radius: 4px;
    background-color: ${colors.basic.white};
    margin-bottom: 5px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
        transform: ${(props) => (props.visibleItem ? 'rotate(42deg)' : 'rotate(0)')};
        background-color: ${(props) => (props.visibleItem ? `${colors.basic.black}` : `${colors.basic.white};`)};
    }
    &:nth-child(2) {
        transform: ${(props) => (props.visibleItem ? 'translateX(100%)' : 'translateX(0)')};
        opacity: ${(props) => (props.visibleItem ? '0' : '1')}
    }
    &:nth-child(3) {
        transform: ${(props) => (props.visibleItem ? 'rotate(-42deg)' : 'rotate(0)')};
        background-color: ${(props) => (props.visibleItem ? `${colors.basic.black}` : `${colors.basic.white};`)};
    }
`;

export const ContainerHeaderItems = styled.div<MenuInterface>`

    /* media query */
    @media (max-width: 1020px) {
        display: ${(props) => (props.visibleItem ? 'flex' : 'none')};
        transform: ${(props) => (props.visibleItem ? 'translateX(0)' : 'translateX(100%)')};
        transition: transform 2s ease-in-out;
        background-color: ${colors.basic.white};
        flex-flow: column nowrap;
        position: fixed;
        justify-content: flex-start;
        align-items: flex-start;
        right: 0;
        top: 0;
        height: 100vh;
        width: 232px;
    }
`;

export const OpacityMenu = styled.div<MenuInterface>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    z-index: 3;
    padding: 20px 60px;

    /* media query */
    @media (max-width: 1020px) {
        display: ${(props) => (props.visibleItem ? 'flex' : 'none')};
        background-color: rgba(0, 0, 0, 0.5);
        height: 100vh;
        width: 100vw;
        z-index: 3;
        position: fixed;
    };
`;

export const ImageIcon = styled.img`
    display: none;

    /* media query */
    @media (max-width: 1020px) {
        display: flex;
        height: 22px;
        margin-left: 10px;
        margin-right: 20px;
        width: 22px;
    };
`;

export const HeaderHamburger = styled.div`
    display: none;

    /* media query */
    @media (max-width: 1020px) {
        align-items: center;
        /* background-color: ${colors.theme.purpleLight}; */
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        height: 50px;
        width: 100%;
    };
`;

export const HamburgerHeaderImage = styled.img`
    width: 120px;
    height: 120px;
`;

export const HeaderItem = styled.a<MenuInterface>`
    margin-right: ${(props) => (props.lastItem ? '0px' : '80px')};
    margin-left: 80px;
    font-size: 16px;
    text-decoration: ${(props) => (props.currentPath ? 'underline' : 'none')};
    color: ${colors.basic.white};
    font-family: 'Quicksand', sans-serif;

    /* media query */
    @media (max-width: 1020px) {
        color: ${colors.basic.black};
        display: flex;
        flex-direction: row;
        font-size: 18px;
        margin-left: 0px;
        padding: 15px;
        text-decoration: none;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    };
`;

export const Footer = styled.div<MenuInterface>`
    display: none;
        /* media query */
        @media (max-width: 1020px) {
            display: flex;
            color: ${colors.basic.black};
            width: 100%;
            height: 100%;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            font-family: 'Lato';
    };
`;
