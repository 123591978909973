import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// styles - home
export const Home = styled.div`
    min-height: 100vh;
    min-width: 100vw;

    overflow-x: hidden;
`;

// styles - header
export const Header = styled.div`
    width: 100vw;
    height: 50vh;
    background-color: ${colors.theme.purpleDark};
`;

export const TitleArea = styled.div`
    display: flex;
    height: 50%;
    align-items: center;
    justify-content: center;
`;

export const TitleHeader = styled.h1`
    text-align: center;
    font-size: 64px;
    padding: 10px;
    font-weight: ${consts.getFontWeight('Lato-Bold')};

    @media(max-width: 600px){
        font-size: 38px;
    }
`;

// styles - body
export const Body = styled.div`
    background-color: ${colors.theme.backgroundWhite};
    width: 100vw;
    height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* media query */
    @media(max-width: 1100px){
        background-color: ${colors.theme.purpleDark};
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

export const CreativeTeam = styled.img`
    height: 60vh;
    /* media query */
    @media(max-width: 1100px){
        height: 40vh;
}
`;

export const Freepik = styled.img`
    position: absolute;
    height: 80%;
    width: 800px;
    bottom: 0;
    right: 0px;

    /* media query */
    @media (max-width: 1400px) {
        width: 600px;
    }
    @media(max-width: 1100px){
        display: none;
    }
`;
// styles - time
export const Time = styled.div`
    display: none;
    margin-bottom: 8vh;

    /* media query */
    @media(max-width: 1400px){
        width: 520px;
        bottom: 20vh;
    }

    @media(max-width: 1200px){
        width: 420px;
        bottom: 15vh;
    }

    @media(max-width: 1100px){
        position: static;
        width: 100vw;
        display: block;
    }
`;

export const TimeText = styled.h2`
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    font-size: 38px;
    text-align: center;

    /* media query */
    @media(max-width: 1400px){
        font-size: 36px;
    }

    @media(max-width: 1200px){
        font-size: 32px;
    }

    @media(max-width: 1100px){
        color: ${colors.basic.white};
        font-size: 28px;
    }

    @media(max-width: 568px){
        font-size: 24px;
        margin-top: 30px;
    }
`;

export const TimeSubtext = styled.h2`
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    font-size: 28px;
    padding: 10px 40px 0px 80px;
    text-align: center;

    /* media query */
    @media(max-width: 1400px){
        font-size: 26px;
    }

    @media(max-width: 1200px){
        padding: 10px 40px 0px 60px;
        font-size: 24px;
    }

    @media(max-width: 1100px){
        color: ${colors.basic.white};
        font-size: 20px;
    }
`;

// styles - containerdevs
export const ContainerDevs = styled.div`
    min-height: 100vh;
    min-width: 100vw;
    background-color: ${colors.theme.backgroundWhite};

    display: grid;
    grid-template-columns: 3fr 3fr;


    /* media query */
    @media(max-width: 1200px){
        flex-direction: column;
        display: flex;
    }

    @media(max-width: 1024px){
        flex-direction: column;
        display: flex;
    }
`;

export const Ceo = styled.div`
    text-align: center;
    padding: 2vh;

    grid-column-start: 1;
    grid-column-end: 3;
`;

export const ImageCeo = styled.img`
    height: 26vh;
    height: 26vh;
    border-radius: 50%;
`;

export const Devs = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    grid-column-start: 1;
    grid-column-end: 3;


    /* media query */
    @media(max-width: 1024px){
        flex-direction: column;
    }
`;

export const DivImage = styled.div`
    flex-direction: column;
    margin: 6vh;
`;

export const ContainerTexts = styled.div`
    text-align: center;
    color: ${colors.basic.black};
`;

export const CardSubtitulo = styled.h3`
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    padding: 2vh;
    font-style: italic;
`;

export const Container = styled.div``;
export const ImageLine = styled.img``;
export const CardText = styled.div``;
export const CardTitulo = styled.h2``;
