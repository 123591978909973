/* eslint-disable max-len */
// libraries
import React from 'react';

// js
import {
    Header,
    Home,
    Body,
    Container,
    Computer,
    ContainerTechnology,
    Technology,
    Freepik,
    Vastro,
    VastroText,
    VastroSubtext,
    ContainerNext,
    CardImage,
    CardText,
    CardTitulo,
    CardSubtitulo,
    ListTecnology,
    ContainerTexts,
    DivBlind,
} from './technologyStyles';

// Images
import computador from '../../assets/images/computador.png';
import freepik from '../../assets/images/freepik.png';
import next from '../../assets/images/next.png';

// components
import Menu from '../../components/Menu/MenuComponent';
import Footer from '../../components/Footer/FooterComponent';

// js
const TecnologiaPage: React.FC = () => {
    // renders
    function arrowNext() {
        return (
            <ContainerNext>
                <CardImage src={next} />
            </ContainerNext>
        );
    }

    function renderCardText(title: string, subtitle: string) {
        return (
            <CardText>
                {arrowNext()}
                <ContainerTexts>
                    <CardTitulo>{title}</CardTitulo>
                    <CardSubtitulo>{subtitle}</CardSubtitulo>
                </ContainerTexts>
            </CardText>
        );
    }

    // main
    return (
        <Home>
            <Container>
                <Header>
                    <Menu />
                    <ContainerTechnology>
                        <Technology>
                            Tecnologias completas para seu negócio
                        </Technology>
                    </ContainerTechnology>
                </Header>
                <Body>
                    <Computer src={computador} />
                    <Freepik src={freepik} />
                    <Vastro>
                        <VastroText>
                            Vasto repertório
                        </VastroText>
                        <VastroSubtext>
                            Nossa equipe domina as principais stacks do mercado e está sempre atualizada com os conhecimentos mais recentes nas áreas de Web e Mobile.
                        </VastroSubtext>
                    </Vastro>
                </Body>
            </Container>
            <DivBlind height={40} />
            <ListTecnology>
                {renderCardText('Back-End', 'Node, Express, TypeScript, JavaScript')}
                {renderCardText('Banco de dados', 'Postgres, MongoDB, Firebase, Realm')}
                {renderCardText('Cloud Computing', 'Heroku, Amazon AWS, Digital Ocean, Google Cloud, Azure')}
                {renderCardText('Infra e CI/CD', 'Docker')}
                {renderCardText('Front-End', 'ReactJS, TypeScript, JavaScript')}
                {renderCardText('Mobile', 'React Native, TypeScript, JavaScript')}
                {renderCardText('Testes', 'Jest')}
            </ListTecnology>
            <DivBlind height={40} />
            <Footer />
        </Home>
    );
};

export default TecnologiaPage;
