// constants
const secondsAnimatedHome = 3;

const urlFacebook = 'https://www.facebook.com/dotcoding.br/';
const urlInstagram = 'https://instagram.com/dotcoding.br?igshid=1suy32kd9popw';
const urlLinkedin = 'https://www.linkedin.com/company/dotcodingbr/';
const urlWhatsApp = 'https://api.whatsapp.com/send?phone=5583988682843';

export default {
    secondsAnimatedHome,
    urlFacebook,
    urlInstagram,
    urlLinkedin,
    urlWhatsApp,
};
