const isProduction = window.location.hostname.includes('dotcoding.com.br');
const isStaging = window.location.hostname.includes('staging');
const getBaseUrl = () => {
    if (isProduction) {
        return 'https://api.dotcoding.com.br';
    }
    if (isStaging) {
        return 'https://staging.dotcodingapi.dotcoding.com.br';
    }
    return 'https://development.dotcodingapi.dotcoding.com.br';
};
const baseUrl = getBaseUrl();
export default baseUrl;
