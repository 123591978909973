// libraries
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// js
import {
    Header,
    HamburgerOpen,
    OpenIcon,
    ContainerHeaderItems,
    HeaderHamburger,
    // HamburgerHeaderImage,
    HeaderItem,
    ImageIcon,
    HeaderItemLogo,
    OpacityMenu,
    Footer,
} from './menuStyles';

// images
// import dotcodingHamburgerLogo from '../../assets/images/dotcodingHamburgerLogo.png';
import houseIcon from '../../assets/images/houseIcon.png';
import bookIcon from '../../assets/images/bookOpen.png';
// import technologyIcon from '../../assets/images/technologyIcon.png';
import contactIcon from '../../assets/images/contactIcon.png';
import workIcon from '../../assets/images/workIcon.png';
// import teamIcon from '../../assets/images/teamIcon.png';

const Menu: React.FC = () => {
    // consts
    const location = useLocation();

    // states
    const [visibleItem, setVisibleItem] = useState(false);
    const [currentLocation, setCurrentLocation] = useState('/');

    // effects
    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location]);

    // methods
    const tradeVisibleItem = () => {
        setVisibleItem(!visibleItem);
    };

    // renders
    return (
        <Header>
            <HeaderItemLogo href="/">D O T C O D I N G</HeaderItemLogo>
            <HamburgerOpen onClick={tradeVisibleItem} visibleItem={visibleItem}>
                <OpenIcon visibleItem={visibleItem} />
                <OpenIcon visibleItem={visibleItem} />
                <OpenIcon visibleItem={visibleItem} />
            </HamburgerOpen>
            <OpacityMenu visibleItem={visibleItem}>
                <ContainerHeaderItems visibleItem={visibleItem}>
                    <HeaderHamburger />
                    <HeaderItem
                        href="/"
                        currentPath={currentLocation === '/'}
                        lastItem
                    >
                        <ImageIcon src={houseIcon} />
                        Home
                    </HeaderItem>
                    {/* <HeaderItem
                        href="/technology"
                        currentPath={currentLocation === '/technology'}
                        lastItem
                    >
                        <ImageIcon src={technologyIcon} />
                        Tecnologias
                    </HeaderItem> */}
                    {/* <HeaderItem
                        href="/team"
                        currentPath={currentLocation === '/team'}
                        lastItem
                    >
                        <ImageIcon src={teamIcon} />
                        Nossa equipe
                    </HeaderItem> */}
                    {/* <HeaderItem
                        href="/portfolio"
                        currentPath={currentLocation === '/portfolio'}
                        lastItem
                    >
                        <ImageIcon src={bookIcon} />
                        Portfólio
                    </HeaderItem> */}
                    <HeaderItem
                        href="/work"
                        currentPath={currentLocation === '/work'}
                        lastItem
                    >
                        <ImageIcon src={workIcon} />
                        Trabalhe conosco
                    </HeaderItem>
                    <HeaderItem
                        href="/contact"
                        currentPath={currentLocation === '/contact'}
                        lastItem
                    >
                        <ImageIcon src={contactIcon} />
                        Contato
                    </HeaderItem>
                    <Footer lastItem>
                        Versão 2.0
                    </Footer>
                </ContainerHeaderItems>
            </OpacityMenu>
        </Header>
    );
};

export default Menu;
