// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';
import constants from './homeConstants';
import imageDotCoding from '../../assets/images/imageDotCoding.png';
import imageDotCodingMobile from '../../assets/images/imageDotcodingMobile.png';
import fundoEstatisticas from '../../assets/images/fundoEstatisticas.png';
import fundoCompany from '../../assets/images/fundoCompany.png';

// interfaces
interface TitleHomeInterface {
    fontSize?: number,
    fontWeight?: number,
    marginTop?: number,
    color?: string,
    display?: string,
}

interface ImageCompanyInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    backGroundImage?: boolean,
}

interface TitlePageInterface {
    color?: string,
    paddingRight?: number,
    paddingLeft?: number,
}

interface DivBlindInterface {
    height?: number,
}

interface ScrollYInterface {
    scrollY: boolean,
}

// styles
export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: hidden;
    background-color: white;
`;

// styles - home
export const Home = styled.div`
    background-image: url(${imageDotCoding});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    min-width: 100%;
    @media (max-width: 425px) {
        background-image: url(${imageDotCodingMobile});
    }
`;

export const Body = styled.div`
    /* height: calc(100vh - 90px); */
    padding-top: 170px;
`;

export const ContainerRocket = styled.div`
    background-color: ${colors.rgba.purpleDark};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
`;

export const Rocket = styled.img`
    position: absolute;
    height: 100vh;
    width: 100vw;

    /* media query */
    @media (max-width: 1024px) {
        width: 60vw;
        height: 50vh;
    }
`;

export const GoutToRocket = styled.img`
    position: absolute;
    width: 40vw;
    height: 56vh;
    z-index: 0;

    /* media query */
    @media (max-width: 1024px) {
        width: 60vw;
        height: 42vh;
    }
`;

export const ContainerInfo = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const TitleHome = styled.text<TitleHomeInterface>`
    font-weight: ${(props) => props.fontWeight};
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${colors.basic.white};
    margin-top: ${(props) => `${props.marginTop || 0}px`};
    text-align: center;
    max-width: 1000px;
    max-height: 135px;
    font-family: 'Quicksand', sans-serif;

    /* media query */

    @media (max-width: 1980px) {
        font-size: ${(props) => `${(props.fontSize || consts.fontSize.default) / 0.9}px`};
    }
    @media (max-width: 1024px) {
        font-size: ${(props) => `${(props.fontSize || consts.fontSize.default) / 1.1}px`};
        padding: 10px;
    }
    @media (max-width: 680px) {
        font-size: ${(props) => `${(props.fontSize || consts.fontSize.default) / 1.5}px`};
        padding: 10px 10px;
    }
`;

export const GoutToHomeRight = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10vw;
    height: 16vh;

    /* media query */
    @media (max-width: 1024px) {
        display: none;
    }
`;

export const DivBlind = styled.div<DivBlindInterface>`
    height: ${(props) => `${props.height || 0}px`};
    background-color: ${colors.basic.white};
`;

// styles - solutions
export const Solutions = styled.div`
    background-color: ${colors.basic.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 100vw; */
    text-align: center;
    margin-bottom: 100px;

    /* media query */
    @media (max-width: 1280px) {
        min-height: 140vh;
    }
    @media (max-width: 390px) {
        min-height: 150vh;
    }
`;

export const ContainerTitlePage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
`;

export const TitlePage = styled.h1<TitlePageInterface>`
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    font-family: 'Quicksand', sans-serif;
    color: ${(props) => props.color || colors.basic.white};
    padding-right: ${(props) => `${props.paddingRight || 0}px`};
    padding-left: ${(props) => `${props.paddingLeft || 0}px`};

    /* media query */
    @media (max-width: 600px) {
        font-size: 32px;
    }
    @media (max-width: 425px) {
        font-size: 24px;
    }
`;

export const DivSeparation = styled.div`
    background: red;
    display: flex;
    height: 100px;
`;

export const ContainerCards = styled.div<ScrollYInterface>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    visibility: ${(props) => (props.scrollY ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.scrollY ? 1 : 0)};
    transition: opacity ${constants.secondsAnimatedHome}s, visibility ${constants.secondsAnimatedHome}s;
`;

export const ContainerRowCards = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        width: 80vw;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        margin-top: 0px;
    }
`;

// styles - dot numbers
export const DotNumbers = styled.div`
    width: 100vw;
    background-image: url(${fundoEstatisticas});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const DivBackgroundItens = styled.div`
    background-color: ${colors.rgba.purpleDark};
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 50px;

    /* media query */
    @media (max-width: 680px) {
        flex-direction: column;
    }
`;

export const BodyItens = styled.div`
    font-size: 20px;
`;

export const IntemTitle = styled.h1`
    max-width: 300px;
    max-height: 400px;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    padding: 50px 100px;

    @media (max-width: 780px) {
        font-size: 54px;
        line-height: 80px;
        padding: 50px 50px;
    }
    /* media query */
    @media (max-width: 680px) {
        font-size: 36px;
        max-width: 300px;
        max-height: 400px;
        line-height: 50px;
        padding: 30px 30px;
    }
`;

export const ContainerNumbers = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;

    /* media query */
    @media (max-width: 680px) {
        flex-direction: column;
        margin-top: 0px;
    }
`;

// styles - our differences
export const OurDifferences = styled.div`
    background-color: ${colors.basic.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-align: justify;
`;

export const ContainerAreText = styled.div<TitleHomeInterface>`
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${(props) => `${props.fontSize}px`};
    display: ${(props) => props.display || 'flex'};
    line-height: 22px;
    flex-direction: column;
    display: flex;
    gap: 10px;
    font-style: normal;
    text-align: justify;

    @media (max-width: 1980px) {
        font-size: 24px;
        gap: 20px;
        font-style: normal;
        text-align: justify;
        line-height: 35px;
    }

    @media (max-width: 1600px) {
        font-size: 16px;
        gap: 15px;
        line-height: 30px;
    }

    @media (max-width: 1300px) {
        font-size: 26px;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        gap: 10px;
    }

    @media (max-width: 1280px) {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: justify;
        gap: 15px;
    }

    @media (max-width: 600px) {
        text-align: justify;
        width: 100%;
    }

`;

export const ContainerAreTextTitle = styled.div<TitleHomeInterface>`
    color: ${(props) => props.color};
    font-family: 'Quicksand', sans-serif;
    font-weight: ${(props) => props.fontWeight};
    font-size: ${(props) => `${props.fontSize}px`};
    font-size: 36px;
    width: 100%;
    margin-bottom: 40px;

    @media (max-width: 1600px) {
        font-size: 36px;
    }

    @media (max-width: 1100px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;

export const ContainerAreaTextAre = styled.div`
    display: flex;

    @media (max-width: 1200px) {
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 600px) {
        align-items: center;
        justify-content: center;
    }
`;

export const WhoAre = styled.div`
    background-color: ${colors.basic.white};
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

export const ContainerAreImage = styled.img`
    margin-left: 50px;

    @media (max-width: 1100px) {
        margin-left: 0px;
    }

    @media (max-width: 600px) {
        display: none;
    }
`;

export const ContainerAreImageOne = styled.img`
    display: none;

    @media (max-width: 600px) {
        display: flex;
        margin-bottom: 40px;
        width: 100%;
    }
`;

export const ContainerCompany = styled.div<ImageCompanyInterface>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100vw;
    height: 385px;
    background-color: ${colors.rgba.purpleDark};
    background-image: ${(props) => (props.backGroundImage && `url(${fundoCompany})`)};
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 600px) {
        ${(props) => (!props.backGroundImage && 'height: 200px;')};
        height: 200px;
    }
`;

export const TextCompany = styled.a`
    color: ${colors.basic.white};
    width: 700px;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;

    @media (max-width: 680px) {
        width: 300px;
        font-size: 24px;
    }
`;

export const DivAreaDiferential = styled.div<ScrollYInterface>`
    visibility: ${(props) => (props.scrollY ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.scrollY ? 1 : 0)};
    transition: opacity ${constants.secondsAnimatedHome}s, visibility ${constants.secondsAnimatedHome}s;
    display: flex;
    align-items: center;

`;

export const Number = styled.p`
    position: absolute;
    color: ${colors.rgba.purpleNumbers};
    font-size: 72px;
    font-weight: 700;
    top: -39px;
    left: -39px;
`;

export const ItemSeparation = styled.img`
    /* width: 5px; */
    /* height: 124px; */
    margin-top: 0px;

    @media (max-width: 1024px) {
        margin-bottom: 100px;
        margin-top: 10px;
    }
`;

export const ContainerItensDiferential = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1024px) {
        /* display: grid; */
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const ContainerItensDiferentialItem = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 1024px) {
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
`;

export const AreaImage = styled.div`
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 1024px) {
        min-height: 30vh;
        display: grid;
        justify-content: center;
        align-items: center;
    }
`;

export const ImageItem = styled.img`
    width: 94px;
    height: 94px;

    @media (max-width: 1024px) {
        width: 100px;
    }
`;

export const AreaText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 50vh;

    @media (max-width: 1024px) {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const TextTitle = styled.h1`
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: ${colors.basic.black};
    text-align: center;
`;

export const TextSubTitle = styled.h2`
    color: ${colors.basic.black};
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin-top: 25px;
`;

export const DivBorderImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;

    @media (max-width: 1024px) {
        width: 194px;
        height: 194px;
    }

`;

export const TextNumber = styled.h1`
    color: ${colors.rgba.purpleNumbers};
    position: absolute;
    width: 97px;
    height: 100px;
    margin-right: 200px;
    margin-bottom: 200px;
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 120px;
    width: 97px;
    height: 100px;

    @media (max-width: 1024px) {
        font-size: 72px;
        margin-right: 200px;
        margin-bottom: 200px;
        line-height: 90px;
    }
`;

export const TextNumberRight = styled.h1`
    color: ${colors.rgba.purpleNumbers};
    display: flex;
    margin-right: -200px;
    margin-bottom: 300px;
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 180px;
    width: 111px;
    height: 100px;

    @media (max-width: 1024px) {
        font-size: 72px;
        margin-right: 200px;
        margin-bottom: 200px;
        line-height: 90px;
    }
`;

export const Point = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 71px;
    font-size: 64px;
    margin-bottom: 50px;
    text-align: center;
`;

export const ContainerImage = styled.div`
    width: 50%;
    height: 100vh;
`;

export const DivAreaText = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px 100px;

    @media (max-width: 1200px) {
        font-size: 36px;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 600px) {
        padding: 50px 50px;
    }
`;

export const GapItem = styled.div`
    gap: 20px;
    /* background: blue; */
`;

export const ContainerAreaItens = styled.div`
    display: flex;

    @media (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const TitleNumber = styled.h1`
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 30px;
`;

export const SubTitleNumber = styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
`;

export const CardNumber = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 500px;
`;
