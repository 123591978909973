// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// styles
export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const Contact = styled.div`
    max-height: 100vh;
    max-width: 100vw;
`;

// styles - header
export const Header = styled.div`
    background-color: ${colors.theme.purpleDark};
    height: 40vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

export const TitleHeader = styled.h1`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    padding: 30px;
    /* media query */
    @media (max-width: 1024px) {
        font-size: 50px;
    }
    @media (max-width: 800px) {
        font-size: 40px;
    }
`;

// styles = body
export const Body = styled.div`
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100vw;
`;

export const BalloonBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
    padding: 60px;

    @media (max-width: 800px) {
        padding: 30px;
    }
`;

export const BalloonBodyImg = styled.img`
    width: 100%;

    /* media query */
    @media (max-width: 1350px){
        display: none;
    };
`;

export const ContactArea = styled.div`
    display: none;
    flex: 1;
    flex-direction: column;

    /* media query */
    @media (max-width: 1350px) {
        display: flex;
        margin: 6vh 0px;
    };
`;

export const TitleContact = styled.div`
    color: ${colors.basic.black};
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    @media (max-width: 1000px) {
        height: 200px;
        font-size: 20px;
        line-height: 32px
    };
    @media (max-width: 530px) {
        /* font-size: 25px;
        width: 100%;
        height: 250px; */
    };
`;

export const FormArea = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0px 40px;

    @media (max-width: 1000px) {
        padding: 0px 10px;
    };
`;

export const InputArea = styled.div`
    display: flex;
    flex-direction: row;

    /* media query */
    @media (max-width: 1020px) {
       display: flex;
       flex-direction: column;
    };
`;

export const InputForm = styled.input`
    border: none;
    background-color: ${colors.theme.backgroundWhite};
    color: black;
    flex: 1;
    height: 40px;
    margin-bottom: 15px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 5px;

    /* media query */
    @media (max-width: 1024px) {
        margin-right: 0px;
        margin-top: 20px;
    };
`;

export const Select = styled.div`
    display: flex;
    flex: 1;
    margin-right: 15px;
    margin-top: 20px;

    /* media query */
    @media (max-width: 1024px) {
        margin-right: 0px;
    };
`;

export const SelectArea = styled.select`
    background-color: ${colors.theme.backgroundWhite};
    border: 0px;
    color: gray;
    flex: 1;
    margin-bottom: 15px;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
`;

export const CheckboxArea = styled.div`
    display: flex;
    flex-direction: row;

    /* media query */
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

export const Checkbox = styled.input`
    height: 20px;
    width: 20px;
`;

export const CheckboxTitle = styled.label`
    color: ${colors.basic.black};
    flex: 1;
    margin-left: 10px;

    /* media query */
    @media (max-width: 900px){
        margin-right: 20px;
    };
`;

export const Title = styled.h3`
    color: ${colors.basic.mediumGray};
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const InputTextArea = styled.textarea`
    background-color: ${colors.theme.backgroundWhite};
    border: none;
    flex: 1;
    height: 200px;
    margin-right: 15px;
    padding: 15px;
    resize: none;
    font-style: normal;
    color: ${colors.basic.mediumGray};
    font-family: 'Quicksand';

    @media (max-width: 1024px) {
        margin-right: 0px;
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 30px;

    /* media query */
    @media (max-width: 1024px) {
        margin-right: 0px;
    };
`;

export const InformationText = styled.text`
    color: ${colors.basic.black};
    font-size: 12px;
    margin-top: 10px;

    /* media query */
    @media (max-width: 1024px) {
        font-size: 14px;
    };
`;

export const SendButton = styled.button`
    align-items: center;
    background-color: ${colors.theme.purpleDark};
    border-radius: 5px;
    display: flex;
    font-weight: 700;
    justify-content: center;
    width: 178px;
    height: 50px;
    cursor: pointer;

    /* media query */
    @media (max-width: 1024px) {
        height: 40px;
        width: 120px;
    }
`;

export const SendButtonText = styled.text`
    color: ${colors.basic.white};
`;

export const CheckboxOptions = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 15px;
`;

export const Option = styled.option``;
