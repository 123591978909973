// libraries
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// pages
import HomePage from '../pages/Home/HomePage';
import TecnologiaPage from '../pages/Technology/TechnologyPage';
import ContactPage from '../pages/Contact/ContactPage';
import PortfolioPage from '../pages/Portfolio/PortfolioPage';

import TeamPage from '../pages/Team/TeamPage';
import WorkPage from '../pages/Work/WorkPage';

const Routes: React.FC = () => (
    <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/portfolio" exact component={PortfolioPage} />
        <Route path="/technology" component={TecnologiaPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/team" component={TeamPage} />
        <Route path="/work" component={WorkPage} />
    </Switch>
);

export default Routes;
