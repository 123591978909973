// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// Footer Styles
export const Footer = styled.div`
    background-color: ${colors.theme.purpleDark};
    min-height: 30vh;
    min-width: 100vw;
    padding: 50px 80px;

    /* media query */
    @media(max-width: 900px){
        min-height: 10vh;
    }
`;

export const LinkSocial = styled.a`
    margin: 0px 10px;
    cursor: pointer;
`;

export const SocialMedia = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    /* media query */
    @media(max-width: 900px){
        font-size: 8px;
    }
`;

// Styles Navagation
export const Navigation = styled.h1`
    width: 500px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    font-size: 28px;

    /* media query */
    @media(max-width: 900px){
        font-size: 18px;
        width: 350px;
        flex-wrap: wrap;
        margin-bottom: 5px;
    }
`;

// Styles ImageSocial
export const ContainerImagesSocial = styled.div`
    margin-bottom: 30px;
`;

export const Home = styled.a`
    padding-right: 4%;
    font-size: 20px;
    text-decoration: none;
    color: ${colors.basic.white};
`;

export const Tecnologias = styled.a`
    padding-right: 4%;
    font-size: 20px;
    text-decoration: none;
    color: ${colors.basic.white};
`;

export const FooterComponent = styled.a`
    /* margin-left: 100px; */
    font-weight: 500;
    font-size: 22px;
    text-decoration: none;
    color: ${colors.basic.white};

   /* media query */
    @media (max-width: 1024px) {
        margin-left: 10px;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 11px;
    }
`;

// Styles TitleCopyrigth
export const TitleCopyright = styled.div`
    font-size: 12px;
    text-align: center;
    color: #E0E0E0;

    /* media query */
    @media(max-width: 900px){
        width: 350px;
        height: 9px;
        font-size: 12px;
    }
`;

export const ImageSocial = styled.img`
    width: 25px;
    height: 25px;

    /* media query */
    @media(max-width: 900px){
            width: 22px;
            height: 22px;
    }
`;
