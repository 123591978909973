import axios from 'axios';

const isDevelopment = window.location.hostname.includes('localhost') || window.location.hostname.includes('development') ? 'development' : null;
const isStaging = window.location.hostname.includes('staging') ? 'staging' : null;
const isMaster = window.location.hostname.includes('master') ? 'master' : null;
const environment = isDevelopment || isStaging || isMaster;

const baseURL = environment ? `https://${environment}.dotcodingapi.dotcoding.com.br` : 'https://api.dotcoding.com.br';
const api = axios.create({
    baseURL,
});

export default api;
