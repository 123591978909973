// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

export const Container = styled.div`
    max-height: 100vh;
    max-width: 100vw;
`;

export const Header = styled.div`
    background-color: ${colors.theme.purpleDark};
    height: 298px;
    width: 100vw;
    display: flex;
    flex-direction: column;

    /* media query */
    @media (max-width: 620px) {
        height: 30vh;
    }
`;

export const TitleHeader = styled.h1`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;

    /* media query */
    @media (max-width: 620px) {
        font-size: 40px;
        padding: 0px;
    }
`;

export const Body = styled.div`
    max-width: 100vw;

    /* media query */
    @media (max-width: 1024px) {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    };
`;

export const FormArea = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 50px;

    @media (max-width: 1024px) {
        margin-bottom: 0px;
        margin-left: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
    }
`;

export const TitleForm = styled.h1`
    color: ${colors.basic.black};
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    padding: 10px 0px;

    /* media query */
    @media (max-width: 1024px) {
        font-size: 25px;
        padding-top: 30px;
    }
`;

export const InputForm = styled.input`
    background-color: ${colors.theme.backgroundWhite};
    border: 0px;
    color: ${colors.basic.mediumGray};
    flex: 1;
    width: 249px;
    height: 40px;
    margin-top: 20px;
    margin-right: 40px;
    padding: 15px;
    border-radius: 5px;

    @media (max-width: 1024px) {
        background-color: ${colors.theme.backgroundWhite};
        border: 0px;
        color: ${colors.basic.mediumGray};
        width: 100%;
        height: 40px;
        margin-top: 20px;
        padding: 15px;
        border-radius: 5px;
    }
`;

export const GroupInputArea = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;

    @media (max-width: 1024px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const SelectArea = styled.select`
    background-color: ${colors.theme.backgroundWhite};
    border: 0px;
    color: ${colors.basic.mediumGray};
    flex: 1;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-right: 40px;
    padding: 10px;
    width: 249px;
    height: 40px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media (max-width: 1024px) {
        height: 40px;
        margin-bottom: 0px;
        margin-right: 40px;
        width: 100%;
    }
`;

export const SelectOption = styled.option`
    color: ${colors.basic.mediumGray};
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const ProfessionalArea = styled.div`
    width: 50vw;

    @media (max-width: 1024px) {
        width: 90vw;
    }
`;

export const ProfessionalInput = styled.input`
    background-color: ${colors.theme.backgroundWhite};
    border: 0px;
    color: ${colors.basic.mediumGray};
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
    margin-top: 50px;
    margin-right: 40px;
    padding: 15px;

    @media (max-width: 1024px) {
        margin-right: 0px;
        margin-top: 10px;
    }
`;

export const CheckboxArea = styled.div`
    display: flex;
    flex-direction: row;

    /* media query */
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

export const Title = styled.h1`
    color: ${colors.basic.mediumGray};
    margin-bottom: 30px;
    margin-top: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 1024px) {
        margin-bottom: 20px;
        margin-top: 20px;
    }
`;

export const CheckboxOptions = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 15px;
`;

export const Checkbox = styled.input`
    height: 20px;
    width: 20px;
`;

export const CheckboxTitle = styled.label`
    color: ${colors.basic.black};
    flex: 1;
    margin-left: 10px;

    :after {
        content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
    }
`;

export const InputTextArea = styled.textarea`
    background-color: ${colors.theme.backgroundWhite};
    border: none;
    font-family: ${consts.fontFamily};
    margin-right: 40px;
    margin-top: 30px;
    height: 200px;
    padding: 15px;
    resize: none;

    @media (max-width: 1024px) {
        margin-right: 0px;
    };
`;

export const ButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 10px;
`;

export const InformationText = styled.text`
    color: ${colors.basic.black};
    margin-top: 10px;
    font-size: 12px;

    /* media query */
    @media (max-width: 1024px) {
        /* font-size: 14px; */
    };
`;

export const SendButton = styled.button`
    align-items: center;
    background-color: ${colors.theme.purpleDark};
    border-radius: 5px;
    display: flex;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    width: 178px;
    height: 50px;
    cursor: pointer;

    /* media query */
    @media (max-width: 1024px) {
        margin: 0px;
        height: 40px;
        width: 120px;
    }
`;

export const SendButtonText = styled.text`
    color: ${colors.basic.white};
`;

export const InputFormName = styled.input`
    background-color: ${colors.theme.backgroundWhite};
    border: 0px;
    color: ${colors.basic.mediumGray};
    flex: 1;
    height: 40px;
    margin-top: 20px;
    margin-right: 40px;
    padding: 15px;
    border-radius: 5px;

    /* media query */
    @media (max-width: 1024px) {
        width: 100%;
    }
`;

export const SpanCheckBox = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 2px;
`;
