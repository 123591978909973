/* eslint-disable camelcase */
// libraries
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { BsArrowRightShort } from 'react-icons/bs';

// js
import {
    Container,
    Header,
    TitleHeader,
    ContainerContent,
    DeskImage,
    ContentText,
    Text,
    TextInfo,
    ContainerAdvertising,
    ContainerText,
    TextData,
    ImageAdvertising,
    ContainerCards,
    ImageProp,
    ContainerNull,
    ContainerNews,
    TextCard,
    CardText,
    CellImage,
    ContainerPro,
    ContainerSec,
    ContainerAbrasing,
    ContainerDescriptions,
    Advertisements,
    ContainerLink,
    TextLink,
    TextLinkTwo,
    ShadowCard,
    TextShadowCard,
    ShadowAreaText,
} from './portfolioStyles';

// Images
import ImagePC from '../../assets/images/ImagePC.png';
import SiteProMyPet from '../../assets/images/SiteProMyPet.png';
import CellProMyPet from '../../assets/images/CellPromypet.png';
import CellsProMyPet from '../../assets/images/CellsPromypet.png';

// components
import Menu from '../../components/Menu/MenuComponent';
import Footer from '../../components/Footer/FooterComponent';

import Apps, { IResult } from './portfolioConstants';

// services
import { getAllPortfolio } from '../../services/portifolio';

// interfaces
interface PortfolioProps {
    id: number;
    name: string;
    cover: string;
    text: string;
    secondary_text: string;
    link_android: string;
    link_ios: string;
    link_web: string;
    attachment: Array<string>;
}

const PortfolioPage: React.FC = () => {
    // useState
    const [appActual, setAppActual] = useState<PortfolioProps>();
    const [idAppActual, setIdAppActual] = useState<number | null>();
    const [nameAppActual, setnameAppActual] = useState('');
    const [apps, setApps] = useState<PortfolioProps[]>([] as PortfolioProps[]);

    // renders
    const renderDescriptions = (e: Array<string>) => e.map((v: string, i: number) => {
        if (!i) {
            return (
                <ContainerDescriptions>
                    <TextCard key={v} marginBottom="0px">
                        {`${v.split(':')[0]}:`}
                    </TextCard>
                    <TextCard key={v} marginBottom="0px">
                        {`${v.split(':')[1]};`}
                    </TextCard>
                </ContainerDescriptions>
            );
        }
        return (
            <ContainerDescriptions>
                <TextCard key={v} marginBottom="0px">
                    {e.length - 1 === i ? v : `${v};`}
                </TextCard>
            </ContainerDescriptions>
        );
    });

    function imageTask() {
        if (apps) {
            return apps.map((value) => {
                const tradeFrame = () => {
                    setAppActual(value);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                };
                const onEnterApp = () => {
                    setIdAppActual(value.id);
                    setnameAppActual(value.name);
                };
                const onLeaveApp = () => {
                    setIdAppActual(null);
                };
                return (
                    <Advertisements
                        onMouseEnter={onEnterApp}
                        onMouseLeave={onLeaveApp}
                    >
                        <ImageAdvertising
                            src={value.cover}
                            key={value.id}
                            onClick={tradeFrame}
                        />
                        {idAppActual === value.id ? (
                            <ShadowCard onClick={tradeFrame}>
                                <ShadowAreaText>
                                    <TextShadowCard>
                                        {nameAppActual}
                                    </TextShadowCard>
                                    <BsArrowRightShort
                                        size={32}
                                        color="white"
                                    />
                                </ShadowAreaText>
                            </ShadowCard>
                        ) : null}
                    </Advertisements>
                );
            });
        }
        return null;
    }

    // function taskText() {
    // if (appActual) {
    //     return appActual.text.map((v: string) => (
    //         <CardText key={v}>{v}</CardText>
    //     ));
    // }
    // return null;
    // }

    function uniqueTexts() {
        if (appActual) {
            return (
                <>
                    <ContainerText>
                        <TextData>{nameAppActual}</TextData>
                    </ContainerText>
                    <ContainerNull>
                        <ContainerCards>
                            <ImageProp src={appActual.cover} />
                        </ContainerCards>
                    </ContainerNull>
                    {/* <ContainerNews>{taskText()}</ContainerNews> */}
                    <ContainerAbrasing>
                        <ContainerPro>
                            <CellImage src={appActual.attachment[0]} />
                            <CellImage src={appActual.attachment[1]} />
                        </ContainerPro>
                        <ContainerSec>
                            {renderDescriptions(
                                appActual.text.split(';'),
                            )}
                        </ContainerSec>
                        <ContainerSec>
                            {renderDescriptions(
                                appActual.secondary_text.split(';'),
                            )}
                        </ContainerSec>
                        <ContainerLink>
                            Acesse pelos links:
                            <TextLink>
                                Android:
                                {' '}
                                {appActual.link_android}
                            </TextLink>
                            <TextLinkTwo>
                                iOS:
                                {' '}
                                {appActual.link_ios}
                            </TextLinkTwo>
                        </ContainerLink>
                    </ContainerAbrasing>
                </>
            );
        }
        return (
            <>
                <ContainerContent>
                    <DeskImage src={ImagePC} />
                    <ContentText>
                        <Text>Portfólio de aplicativos e plataformas</Text>
                        <TextInfo>
                            Portfólio de aplicativos e sites responsivos,
                            disponiveis para Android, iOS e Web, feitos para
                            impulsionar seu negócio. Confira abaixo alguns
                            projetos desenvolvidos pela nossa equipe.
                        </TextInfo>
                    </ContentText>
                </ContainerContent>
                <ContainerText>
                    <TextData>Conheça</TextData>
                </ContainerText>
                <ContainerAdvertising>{imageTask()}</ContainerAdvertising>
            </>
        );
    }

    // buscar todos os projetos do portfolio
    async function getAllProjectPortfolio() {
        getAllPortfolio().then((response: any) => {
            setApps(response?.result?.list);
        }).catch((error) => error);
    }

    useEffect(() => {
        getAllProjectPortfolio();
    }, []);

    // main
    return (
        <Container>
            <ToastContainer />
            <Header>
                <Menu />
                <TitleHeader>Portfólio</TitleHeader>
            </Header>
            {uniqueTexts()}
            <Footer />
        </Container>
    );
};
export default PortfolioPage;
