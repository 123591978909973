import { AxiosResponse } from 'axios';
import api from './api';

async function getAllPortfolio(): Promise<AxiosResponse> {
    return api
        .get('/website/portfolio')
        .then((response) => response.data)
        .catch((error) => error);
}

export {
    getAllPortfolio,
};
