// libraries
import React from 'react';
import PropTypes from 'prop-types';

// js
import {
    Container,
    Image,
    Title,
    SubTitle,
} from './cardStyles';
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// interfaces
import type { CardInterface } from './cardInterfaces';

// main
const CardStatistics: React.FC<CardInterface> = (props) => {
    // consts
    const {
        backgroundColor,
        sourceImage,
        title,
        colorTitle,
        fontWeightTitle,
        fontSizeTitle,
        subtitle,
        colorSubTitle,
        fontWeightSubTitle,
        fontSizeSubTitle,
        borderWidth,
    } = props;

    // render
    return (
        <Container borderWidth={borderWidth} backgroundColor={backgroundColor}>
            {sourceImage && <Image src={sourceImage} />}
            <Title
                colorTitle={colorTitle}
                fontWeightTitle={fontWeightTitle}
                fontSizeTitle={fontSizeTitle}
            >
                {title}
            </Title>
            <SubTitle
                colorSubTitle={colorSubTitle}
                fontWeightSubTitle={fontWeightSubTitle}
                fontSizeSubTitle={fontSizeSubTitle}
            >
                {subtitle}
            </SubTitle>
        </Container>
    );
};

// default props
CardStatistics.defaultProps = {
    backgroundColor: colors.basic.white,
    sourceImage: '',
    colorTitle: colors.basic.black,
    fontWeightTitle: consts.getFontWeight('Lato-Bold'),
    fontSizeTitle: 1.5,
    colorSubTitle: colors.basic.black,
    fontWeightSubTitle: consts.getFontWeight('Lato-Regular'),
    fontSizeSubTitle: 1,
    borderWidth: false,
};

// prop types
CardStatistics.propTypes = {
    backgroundColor: PropTypes.string,
    sourceImage: PropTypes.string,
    colorTitle: PropTypes.string,
    fontWeightTitle: PropTypes.number,
    fontSizeTitle: PropTypes.number,
    colorSubTitle: PropTypes.string,
    fontWeightSubTitle: PropTypes.number,
    fontSizeSubTitle: PropTypes.number,
    borderWidth: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
};

export default CardStatistics;
