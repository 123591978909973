/* eslint-disable react/button-has-type */
// libraries
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

// js
import {
    Container,
    Header,
    TitleHeader,
    Body,
    FormArea,
    TitleForm,
    InputForm,
    GroupInputArea,
    SelectArea,
    SelectOption,
    ProfessionalArea,
    ProfessionalInput,
    CheckboxArea,
    Title,
    CheckboxOptions,
    Checkbox,
    CheckboxTitle,
    InputTextArea,
    ButtonArea,
    InformationText,
    SendButton,
    InputFormName,
    SendButtonText,
} from './workStyles';
import baseUrl from '../../constants/baseUrl';
import colors from '../../styles/colors';

// components
import Menu from '../../components/Menu/MenuComponent';
import Footer from '../../components/Footer/FooterComponent';
// interface
interface FormCandidateInterface {
    name: string,
    cpf: string,
    birthDate: string,
    gender: string,
    maritalStatus: string,
    jobOccupation: string[],
    availableTime: string,
    email: string,
    telephone: string,
    about: string,
}

const WorkPage: React.FC = () => {
    // consts
    const initialFormCandidate: FormCandidateInterface = {
        name: '',
        cpf: '',
        birthDate: '',
        gender: '',
        maritalStatus: '',
        jobOccupation: [],
        availableTime: '',
        email: '',
        telephone: '',
        about: '',
    };

    // state
    const [formCandidate, setFormCandidate] = useState(initialFormCandidate);
    const [lodingItem, setLodingItem] = useState(false);

    // methods
    function onChangeForm(e: React.ChangeEvent<
        HTMLInputElement |
        HTMLSelectElement |
        HTMLTextAreaElement
    >) {
        const keyChanged = e.target.id;
        const valueChanged = e.target.value;
        const newFormCandidate = {
            ...formCandidate,
            [keyChanged]: valueChanged,
        };
        setFormCandidate(newFormCandidate);
    }

    async function onSendForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLodingItem(true);
        const sendFormCandidate = formCandidate;
        const configRequest = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios.post(`${baseUrl}/website/resumes`, sendFormCandidate, configRequest)
            .then(() => {
                toast.success('Currículo enviado com sucesso!');
                setLodingItem(false);
                setFormCandidate(initialFormCandidate);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLodingItem(false);
            });
    }

    const formatCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.maxLength = 14;
        let { value } = e.currentTarget;
        if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
            value = value.replace(/\D/g, '');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d)/, '$1.$2');
            value = value.replace(/(\d{3})(\d)/, '$1-$2');

            e.currentTarget.value = value;
        }
    }, []);

    const formatDate = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.maxLength = 10;
        let { value } = e.currentTarget;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '$1/$2');
        value = value.replace(/(\d{2})(\d)/, '$1/$2');

        e.currentTarget.value = value;
    }, []);

    const formatTelephone = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.maxLength = 15;
        let { value } = e.currentTarget;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '($1) $2');
        value = value.replace(/(\d{5})(\d)/, '$1-$2');

        e.currentTarget.value = value;
    }, []);

    // renders
    const Option = (
        optionName: string,
        placeholderOption: boolean,
        value: string,
    ) => (
        <SelectOption
            disabled={placeholderOption}
            selected={placeholderOption}
            value={value}
        >
            {optionName}
        </SelectOption>
    );

    const CheckboxComponent = (title: string, value: string) => {
        function onChangeBox() {
            let jobOccupation = formCandidate?.jobOccupation || [];
            const conditionForAddNewSkill = jobOccupation.includes(value);
            if (conditionForAddNewSkill) {
                jobOccupation = jobOccupation.filter((el) => el !== value);
            } else {
                jobOccupation.push(value);
            }
            const newFormCandidate = {
                ...formCandidate,
                jobOccupation,
            };
            setFormCandidate(newFormCandidate);
        }

        return (
            <CheckboxOptions>
                <Checkbox
                    type="checkbox"
                    onChange={onChangeBox}
                    checked={formCandidate.jobOccupation.includes(value)}
                    value={value}
                />
                <CheckboxTitle>{title}</CheckboxTitle>
            </CheckboxOptions>
        );
    };

    return (
        <Container>
            <ToastContainer />
            <Header>
                <Menu />
                <TitleHeader>Trabalhe conosco</TitleHeader>
            </Header>
            <Body>
                <FormArea onSubmit={onSendForm}>
                    <TitleForm>INFORMAÇÕES PESSOAIS</TitleForm>
                    <InputFormName
                        required
                        placeholder="*Nome completo"
                        id="name"
                        value={formCandidate.name}
                        onChange={onChangeForm}
                        maxLength={250}
                        type="text"
                    />
                    <GroupInputArea>
                        <InputForm
                            placeholder="*CPF"
                            required
                            id="cpf"
                            onKeyUp={formatCpf}
                            value={formCandidate.cpf}
                            onChange={onChangeForm}
                        />
                        <InputForm
                            placeholder="*Data de nascimento"
                            required
                            id="birthDate"
                            onKeyUp={formatDate}
                            value={formCandidate.birthDate}
                            onChange={onChangeForm}
                        />
                        <SelectArea
                            required
                            id="gender"
                            value={formCandidate.gender}
                            onChange={onChangeForm}
                        >
                            {Option('*Sexo', true, '')}
                            {Option('Masculino', false, 'masculine')}
                            {Option('Feminino', false, 'feminine')}
                            {Option('Outros', false, 'Outros')}
                        </SelectArea>
                        <SelectArea
                            required
                            id="maritalStatus"
                            value={formCandidate.maritalStatus}
                            onChange={onChangeForm}
                        >
                            {Option('*Estado civil', true, '')}
                            {Option('Solteiro', false, 'single')}
                            {Option('Casado', false, 'married')}
                            {Option('Separado', false, 'separate')}
                        </SelectArea>
                    </GroupInputArea>
                    <ProfessionalArea>
                        <TitleForm>INFORMAÇÕES PROFISSIONAIS</TitleForm>
                        <Title>*Qual sua área de atuação?</Title>
                        <CheckboxArea>
                            {CheckboxComponent('Web', 'web')}
                            {CheckboxComponent('Servidor', 'servidor')}
                        </CheckboxArea>
                        <CheckboxArea>
                            {CheckboxComponent('Design', 'design')}
                            {CheckboxComponent('QA', 'qa')}
                        </CheckboxArea>
                        <CheckboxArea>
                            {CheckboxComponent('Mobile', 'mobile')}
                        </CheckboxArea>
                        <ProfessionalInput
                            placeholder="*Disponibilidade de horário"
                            required
                            id="availableTime"
                            value={formCandidate.availableTime}
                            onChange={onChangeForm}
                            maxLength={250}
                        />
                    </ProfessionalArea>
                    <TitleForm>CONTATO</TitleForm>
                    <GroupInputArea>
                        <InputForm
                            placeholder="*E-mail"
                            required
                            id="email"
                            type="email"
                            value={formCandidate.email}
                            onChange={onChangeForm}
                        />
                        <InputForm
                            placeholder="*Telefone"
                            required
                            id="telephone"
                            onKeyUp={formatTelephone}
                            value={formCandidate.telephone}
                            onChange={onChangeForm}
                        />
                    </GroupInputArea>
                    <InputTextArea
                        placeholder="Conte mais sobre você"
                        id="about"
                        value={formCandidate.about}
                        onChange={onChangeForm}
                        maxLength={1000}
                    />
                    <InformationText>*Campos obrigatórios</InformationText>
                    <ButtonArea>
                        <SendButton>
                            { lodingItem ? <BallTriangle color={`${colors.theme.purpleLight}`} height={30} width={30} /> : (
                                <SendButtonText>Enviar</SendButtonText>
                            )}
                        </SendButton>
                    </ButtonArea>
                </FormArea>
            </Body>
            <Footer />
        </Container>
    );
};

export default WorkPage;
