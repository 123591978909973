// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// interfaces
interface DivBlindInterface {
    height?: number,
}

// styles
export const Home = styled.div`
    min-height: 100vh;
    min-width: 100vw;
`;

export const Header = styled.div`
    width: 100vw;
    height: 50vh;
    background-color: ${colors.theme.purpleDark};

    /* media query */
    @media(max-width: 1024px){
        height: 40vh;
    }
`;

export const ContainerTechnology = styled.div`
    display: flex;
    height: 50%;
    align-items: center;
    justify-content: center;
`;

export const Technology = styled.h1`
    text-align: center;
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    font-size: 64px;

    /* media query */
    @media(max-width: 1024px){
        font-size: 40px;
        margin-top: 100px;
    }

    @media(max-width: 568px){
        font-size: 28px;
        margin-top: 50px;
    }
`;

export const Computer = styled.img`
    height: 50vh;

    /* media query */
    @media(max-width: 1024px){
        height: 30vh;
    }
`;

export const Freepik = styled.img`
    position: absolute;
    right: 0px;
    bottom: 0px;

    /* media query */
    @media(max-width: 1400px){
        width: 620px;
    }

    @media(max-width: 1200px){
        width: 520px;
    }

    @media(max-height: 880px){
        width: 660px;
    }

    @media(max-height: 800px){
        width: 560px;
    }

    @media(max-height: 700px){
        width: 460px;
    }

    @media(max-width: 1024px){
        display: none;
    }
`;

export const ContainerFreepik = styled.div`
    width: 100%;
    height: 60vh;
`;

export const Body = styled.div`
    width: 100vw;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /* media query */
    @media(max-width: 1024px){
        background-color: ${colors.theme.purpleDark};
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-between;
        height: 60vh;
    }
`;

export const Vastro = styled.div`
    display: none;

    /* media query */
    @media(max-width: 1400px){
        width: 520px;
        bottom: 20vh;
    }

    @media(max-width: 1200px){
        width: 420px;
        bottom: 15vh;
    }

    @media(max-width: 1024px){
        position: static;
        width: 100vw;
        display: block;
    }
`;

export const VastroText = styled.h2`
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Lato-Bold')};
    font-size: 38px;
    text-align: center;

    /* media query */
    @media(max-width: 1400px){
        font-size: 36px;
    }

    @media(max-width: 1200px){
        font-size: 32px;
    }

    @media(max-width: 1024px){
        color: ${colors.basic.white};
        font-size: 28px;
    }

    @media(max-width: 568px){
        font-size: 20px;
        margin-top: 30px;
    }
`;

export const VastroSubtext = styled.h2`
    color: ${colors.basic.black};
    font-weight: ${consts.getFontWeight('Lato-Regular')};
    font-size: 28px;
    padding: 10px 40px 0px 80px;
    text-align: center;

    /* media query */
    @media(max-width: 1400px){
        font-size: 26px;
    }

    @media(max-width: 1200px){
        padding: 10px 40px 0px 60px;
        font-size: 24px;
    }

    @media(max-width: 1024px){
        color: ${colors.basic.white};
        font-size: 20px;
    }
`;

export const ListTecnology = styled.div`
    min-height: 100vh;
    min-width: 100vw;
    background-color: ${colors.theme.backgroundWhite};
    display: flex;
    flex-wrap: wrap;

    /* media query */
    @media(max-width: 1024px){
        flex-direction: column;
    }
`;

export const CardText = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    min-width: 50vw;
    align-items: center;
    padding: 0px 10vw;
`;

export const CardTitulo = styled.h2`
    color: ${colors.basic.black};
`;

export const CardSubtitulo = styled.h3`
    color: ${colors.basic.mediumGray};
    margin-top: 10px;
`;

export const ContainerTexts = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DivBlind = styled.div<DivBlindInterface>`
    height: ${(props) => `${props.height || 0}px`};
    background-color: ${colors.theme.backgroundWhite};
`;

export const Container = styled.div``;
export const CardImage = styled.img``;
export const ContainerNext = styled.div``;
