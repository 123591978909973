// libraries
import styled from 'styled-components';

// js
import colors from '../../styles/colors';

// styles
export const Container = styled.div`
`;

export const Header = styled.div`
    background-color: ${colors.theme.purpleDark};
    height: 298px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    @media (max-width: 620px) {
        height: 30vh;
    }
`;

export const TitleHeader = styled.h1`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;

    @media (max-width: 620px) {
        font-size: 40px;
        padding: 0px;
    }
`;

export const ContainerContent = styled.div`
    display: flex;
    @media (max-width: 1170px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const DeskImage = styled.img`
    width: 60%;
    height: 40%;
    margin-top: 66px;
    @media (max-width: 600px) {
        width: 249px;
        height: 177px;
    }
`;

export const ContentText = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding-left: 92px;
    @media (max-width: 1170px) {
        align-items: center;
    }
    @media (max-width: 600px) {
        justify-content: center;
        align-items: center;
        padding-left: 1px;
    }
`;

export const Text = styled.text`
    color: ${colors.theme.purpleDark};
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    width: 420px;
    height: 90px;
    margin-top: 169px;
    @media (max-width: 1170px) {
        margin-top: 30px;
    }
    @media (max-width: 600px) {
        width: 261px;
        height: 57px;
        font-size: 22px;
        line-height: 28px;
        margin-top: 30px;
    }
`;

export const TextInfo = styled.text`
    color: ${colors.basic.black};
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    width: 493px;
    height: 92px;
    margin-top: 40px;
    text-align: justify;
    @media (max-width: 600px) {
        font-weight: 200;
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px;
        width: 267px;
        height: 64px;
        text-align: justify;
    }
`;

export const ContainerAdvertising = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 90%;
    min-width: 90%;
    margin-left: 8%;
    @media (max-width: 1170px) {
        padding-right: 20px;
    }
    @media (max-width: 771px) {
        align-items: center;
        justify-content: center;
        padding-right: none;
    }
`;

export const ShadowCard = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 100%;
    width: 100%;
`;

export const ShadowAreaText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TextShadowCard = styled.text`
    color: white;
    font-weight: bold;
    font-size: 18px;
`;

export const Advertisements = styled.div`
    margin: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    border-radius: 10px;
    @media (max-width: 400px) {
        height: 250px;
    }
`;

export const ImageAdvertising = styled.img`
    cursor: pointer;
    border-radius: 10px;
    width: 350px;
    height: 350px;
    object-fit: cover;
    @media (max-width: 400px) {
        height: 250px;
    }
`;

interface Container {
    background: string;
}

export const ImageSecund = styled.div<Container>`
    background-image: ${(props) => (`url(${props.background})`)};
    cursor: pointer;
    @media (max-width: 400px) {
        height: 250px;
    }
`;

export const ContainerText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1170px) {
    }
`;

export const TextData = styled.text`
    color: ${colors.theme.purpleDark};
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    margin-top: 66px;
    margin-bottom: 73px;
    @media (max-width: 400px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @media (max-width: 600px) {
        font-size: 22px;
        line-height: 28px;
    }
`;

export const ContainerNull = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
`;

export const ContainerCards = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ImageProp = styled.img`
    height: 500px;
    margin: 13px;
    object-fit: contain;
    @media (max-width: 1000px) {
        width: 308px;
        height: 154px;
    }
`;

export const ContainerNews = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 74%;
    height: 20%;
    margin-top: 100px;
    margin-left: 8.5%;
    margin-bottom: 59px;
    color: ${colors.basic.black};
    @media (max-width: 800px) {
        margin-top: 50px;
        margin-left: 3.5%;
        margin-bottom: 2px;
    }
`;

export const CardText = styled.text`
    color: ${colors.basic.black};
    display: flex;
    text-align: justify;
    font-family: 'Quicksand';
    font-weight: 400px;
    font-size: 18px;
    width: 100%;
    height: 40px;
    align-self: center;
    margin-left: 12%;
    @media (max-width: 1000px) {
        height: 50px;
        width: 310px;
        font-size: 12px;
        line-height: 15px;
        text-align: justify;
        font-weight: 400;
    }
    @media (max-width: 800px) {
        margin-left: 26%;
    }
`;

export const ContainerAbrasing = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ContainerPro = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    @media (max-width: 950px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const CellImage = styled.img`
    width: 437px;
    height: 330px;
    margin-bottom: 60px;
    @media (max-width: 1170px) {
        margin-bottom: 40px;
        width: 275px;
        height: 207px;
    }
`;

export const ContainerSec = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 85%;
    margin-left: 6%;
    margin-bottom: 2%;
    color: black;
    @media (max-width: 400px) {
        max-width: 87%;
        margin-left: 6%;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: justify;
    }
`;

export const ContainerDescriptions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 90%;
    margin-left: 6%;
    color: black;
    @media (max-width: 400px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-align: justify;
    }
`;

interface TextCardInterfaces {
    marginBottom?: string;
}

export const TextCard = styled.text<TextCardInterfaces>`
    color: ${colors.basic.black};
    display: flex;
    font-family: 'Quicksand';
    font-weight: 400px;
    font-size: 18px;
    width: 100%;
    align-self: flex-start;
    margin-bottom: ${(props) => (props.marginBottom || '20px')};
    @media (max-width: 1000px) {
        font-size: 12px;
        line-height: 15px;
        text-align: justify;
    }
`;

export const ContainerLink = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colors.basic.black};
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    width: 90%;
    margin-left: 11%;
    margin-top: 60px;
    @media (max-width: 1000px) {
        margin-top: 40px;
        margin-left: 11%;
        font-size: 12px;
        line-height: 15px;
    }
`;

export const TextLink = styled.text`
    color: ${colors.basic.black};
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    @media (max-width: 1000px) {
        font-size: 12px;
        line-height: 15px;
    }
`;

export const TextLinkTwo = styled.text`
    color: ${colors.basic.black};
    font-family: 'Quicksand';
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-top: 15px;
    margin-bottom: 69px;
    @media (max-width: 1000px) {
        font-size: 12px;
        line-height: 15px;
    }
`;
