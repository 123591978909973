const colors = {
    basic: {
        black: '#000000',
        white: '#ffffff',
        red: '#ff0000',
        mediumGray: '#505050',
        discreetViolet: '#E3D1E3',
        lightGray: '#CFCFCF',
        matteWhite: '#E5E5E5',
    },
    theme: {
        backgroundWhite: '#f5f1f5',
        purpleDark: '#421C46;',
        purpleLight: '#c8a5c8',
    },
    rgba: {
        purpleDark: 'rgba(66, 28, 70, 0.9)',
        purpleNumbers: 'rgba(66, 28, 70, 0.6);',
    },
};

export default colors;
