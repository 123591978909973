/* eslint-disable max-len */
// libraries
import React from 'react';

// js
import {
    Container,
    Body,
    TitleHeader,
    Header,
    Freepik,
    CreativeTeam,
    ImageCeo,
    ContainerDevs,
    Devs,
    CardText,
    ContainerTexts,
    CardTitulo,
    CardSubtitulo,
    DivImage,
    ImageLine,
    Time,
    TimeText,
    TimeSubtext,
    Home,
    TitleArea,

} from './teamStyles';

// Images
import timefreepik from '../../assets/images/timefreepik.png';
import creativeteam from '../../assets/images/creativeteam.png';
import adler from '../../assets/images/adler.png';
import diego from '../../assets/images/diego.png';
import samuel from '../../assets/images/samuel.png';
import saulo from '../../assets/images/saulo.png';
import line from '../../assets/images/line.png';

// components
import Menu from '../../components/Menu/MenuComponent';
import Footer from '../../components/Footer/FooterComponent';

// js
const TeamPage: React.FC = () => {
    // renders
    function renderCardText(title: string, subtitle: string) {
        return (
            <CardText>
                <ContainerTexts>
                    <CardTitulo>{title}</CardTitulo>
                    <CardSubtitulo>{subtitle}</CardSubtitulo>
                </ContainerTexts>
            </CardText>
        );
    }
    function renderCard(src: string, title: string, subtitle: string) {
        return (
            <DivImage>
                <ImageCeo src={src} />
                {renderCardText(title, subtitle)}
                <ImageLine src={line} />
            </DivImage>
        );
    }

    // main
    return (
        <Home>
            <Container>
                <Header>
                    <Menu />
                    <TitleArea>
                        <TitleHeader>Nossa equipe</TitleHeader>
                    </TitleArea>
                </Header>
                <Body>
                    <CreativeTeam src={creativeteam} />
                    <Freepik src={timefreepik} />
                    <Time>
                        <TimeText>
                            Conheça o time Dotcoding
                        </TimeText>
                        <TimeSubtext>
                            Nossa equipe é formada por profissionais qualificados e comprometidos com a excelência na execução das atividades com o foco em atender as necessidades dos clientes e garantir a qualidade dos serviços prestados.
                        </TimeSubtext>
                    </Time>
                </Body>
            </Container>
            <ContainerDevs>
                <Devs>
                    {renderCard(saulo, 'Saulo Ferreira', 'Founding Executive Director')}
                    {renderCard(samuel, 'Samuel Ferreira', 'Mobile Developer')}
                    {renderCard(diego, 'Diego Cavalcante', 'Mobile Developer')}
                    {renderCard(adler, 'Adler Vinicius', 'Front-End Developer')}
                </Devs>
            </ContainerDevs>
            <Footer />
        </Home>
    );
};

export default TeamPage;
