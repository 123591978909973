// libraries
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

// js
import {
    Container,
    Contact,
    Header,
    TitleHeader,
    Body,
    BalloonBody,
    FormArea,
    InputArea,
    InputForm,
    Select,
    SelectArea,
    Option,
    CheckboxArea,
    Checkbox,
    CheckboxTitle,
    Title,
    ButtonArea,
    InformationText,
    SendButton,
    SendButtonText,
    TitleContact,
    InputTextArea,
    CheckboxOptions,
} from './contactStyle';
import baseUrl from '../../constants/baseUrl';
import colors from '../../styles/colors';

// components
import Footer from '../../components/Footer/FooterComponent';
import Menu from '../../components/Menu/MenuComponent';

// interfaces
interface FormUserInterface {
    name: string,
    email: string,
    telephone: string,
    companyName: string,
    occupationArea: string,
    projectStatus: string,
    needs: string[],
    projectSize: string,
    comments: string,
}

const ContactPage: React.FC = () => {
    // consts
    const initialFormUser: FormUserInterface = {
        name: '',
        email: '',
        telephone: '',
        companyName: '',
        occupationArea: '',
        projectStatus: '',
        needs: [],
        projectSize: '',
        comments: '',
    };

    // states
    const [formUser, setFormUser] = useState(initialFormUser);
    const [lodingItem, setLodingItem] = useState(false);

    // methods
    function onChangeForm(e: React.ChangeEvent<
        HTMLInputElement |
        HTMLSelectElement |
        HTMLTextAreaElement
    >) {
        const keyChanged = e.target.id;
        const valueChanged = e.target.value;
        const newFormUser = {
            ...formUser,
            [keyChanged]: valueChanged,
        };
        setFormUser(newFormUser);
    }

    async function onSendForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLodingItem(true);
        const sendFormUser = formUser;
        const configRequest = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios.post(`${baseUrl}/website/proposals`, sendFormUser, configRequest)
            .then(() => {
                toast.success('Proposta enviada com sucesso!');
                setLodingItem(false);
                setFormUser(initialFormUser);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLodingItem(false);
            });
    }

    const formatTelephone = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.maxLength = 15;
        let { value } = e.currentTarget;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '($1) $2');
        value = value.replace(/(\d{5})(\d)/, '$1-$2');

        e.currentTarget.value = value;
    }, []);

    // renders
    const CheckboxComponent = (title: string, value: string) => {
        function onChangeBox() {
            let needs = formUser?.needs || [];
            const conditionForAddNewNeed = needs.includes(value);
            if (conditionForAddNewNeed) {
                needs = needs.filter((el) => el !== value);
            } else {
                needs.push(value);
            }
            const newFormUser = {
                ...formUser,
                needs,
            };
            setFormUser(newFormUser);
        }

        return (
            <CheckboxOptions>
                <Checkbox
                    type="checkbox"
                    onChange={onChangeBox}
                    checked={formUser.needs.includes(value)}
                    value={value}
                />
                <CheckboxTitle>{title}</CheckboxTitle>
            </CheckboxOptions>
        );
    };

    const SelectOption = (
        optionName: string,
        placeholderOption: boolean,
        value: string,
    ) => (
        <Option
            disabled={placeholderOption}
            selected
            value={value}
        >
            {optionName}
        </Option>
    );

    // main
    return (
        <Container>
            <Contact>
                <Header>
                    <Menu />
                    <TitleHeader>Enviar uma proposta</TitleHeader>
                    <ToastContainer />
                </Header>
                <Body>
                    <FormArea onSubmit={onSendForm}>
                        <BalloonBody>
                            <TitleContact>
                                Para entendermos melhor a sua necessidade e propor a melhor solução
                                para o seu projeto, nos conte um pouco mais sobre a sua ideia e
                                objetivosque deseja alcançar, respondendo o formulário abaixo.
                            </TitleContact>
                        </BalloonBody>
                        <InputArea>
                            <InputForm
                                required
                                type="text"
                                placeholder="*Seu nome"
                                id="name"
                                value={formUser.name}
                                onChange={onChangeForm}
                                maxLength={250}
                            />
                            <InputForm
                                required
                                type="email"
                                placeholder="*Seu e-mail"
                                id="email"
                                value={formUser.email}
                                onChange={onChangeForm}
                                maxLength={250}
                            />
                        </InputArea>
                        <InputArea>
                            <InputForm
                                required
                                type="text"
                                placeholder="*Seu telefone"
                                id="telephone"
                                onKeyUp={formatTelephone}
                                value={formUser.telephone}
                                onChange={onChangeForm}
                            />
                            <InputForm
                                type="text"
                                placeholder="Qual o nome da sua empresa?"
                                id="companyName"
                                value={formUser.companyName}
                                onChange={onChangeForm}
                                maxLength={250}
                            />
                        </InputArea>
                        <InputArea>
                            <InputForm
                                required
                                type="text"
                                placeholder="*Em que área sua empresa trabalha?"
                                id="occupationArea"
                                value={formUser.occupationArea}
                                onChange={onChangeForm}
                                maxLength={250}
                            />
                        </InputArea>
                        <Select>
                            <SelectArea
                                id="projectStatus"
                                value={formUser.projectStatus}
                                onChange={onChangeForm}
                            >
                                {SelectOption('*Qual estágio seu projeto se encontra?', true, '')}
                                {SelectOption('Tenho apenas a ideia', false, 'idea')}
                                {SelectOption('Já tenho um escopo', false, 'scope')}
                                {SelectOption('Estou buscando orçamentos', false, 'budget')}
                                {SelectOption('Já tenho o protótipo', false, 'prototype')}
                                {SelectOption('Já foi iniciado, quero trocar o fornecedor', false, 'to_exchange')}
                                {SelectOption('Já foi iniciado, preciso de melhorias', false, 'improvement')}
                            </SelectArea>
                        </Select>
                        <Title>*Quais suas necessidades?</Title>
                        <CheckboxArea>
                            {CheckboxComponent('Quero um aplicativo móvel', 'i_want_an_app')}
                            {CheckboxComponent('Criação APIs', 'creation_of_api')}
                        </CheckboxArea>
                        <CheckboxArea>
                            {CheckboxComponent('Quero alocar profissionais', 'i_want_to_allocate_professionals')}
                            {CheckboxComponent('Q.A (Testes)', 'qa')}
                        </CheckboxArea>
                        <CheckboxArea>
                            {CheckboxComponent('Integração com outros sistemas', 'integration_with_another_system')}
                            {CheckboxComponent('Gestão de projetos', 'project_management')}
                        </CheckboxArea>
                        <CheckboxArea>
                            {CheckboxComponent('Quero um sistema web', 'i_want_an_web')}
                            {CheckboxComponent('Desenvolvimento de produto', 'product_development')}
                        </CheckboxArea>
                        <Select>
                            <SelectArea
                                id="projectSize"
                                value={formUser.projectSize}
                                onChange={onChangeForm}
                            >
                                {SelectOption('*Qual o tamanho estimado do projeto?', true, '')}
                                {SelectOption('Pequeno (menos de 1 mês)', false, 'little')}
                                {SelectOption('Médio (1-3 meses)', false, 'medium')}
                                {SelectOption('Grande (3-9 meses)', false, 'great')}
                                {SelectOption('Muito grande (mais de 9 meses)', false, 'very_large')}
                                {SelectOption('Não sei informar', false, 'no_information')}
                            </SelectArea>
                        </Select>
                        <InputArea>
                            <InputTextArea
                                placeholder="Comentários"
                                id="comments"
                                value={formUser.comments}
                                onChange={onChangeForm}
                            />
                        </InputArea>
                        <InformationText>*Campos obrigatórios</InformationText>
                        <ButtonArea>
                            <SendButton>
                                { lodingItem ? <BallTriangle color={`${colors.theme.purpleLight}`} height={35} width={35} /> : (
                                    <SendButtonText>Enviar</SendButtonText>
                                )}
                            </SendButton>

                        </ButtonArea>
                    </FormArea>
                </Body>
                <Footer />
            </Contact>
        </Container>
    );
};

export default ContactPage;
