// libraries
import React from 'react';

// Js
import {
    SocialMedia,
    Navigation,
    ContainerImagesSocial,
    TitleCopyright,
    LinkSocial,
    ImageSocial,
    Footer,
    FooterComponent,
} from './footerStyles';
import constants from '../../pages/Home/homeConstants';

// Images
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import linkedin from '../../assets/images/linkedin.png';
import whatsapp from '../../assets/images/whatsapp.png';

const FooterPage: React.FC = () => {
    // methods
    function RenderSocial(src:string, url: string) {
        return (
            <LinkSocial target="_blank" href={url}>
                <ImageSocial src={src} />
            </LinkSocial>
        );
    }

    return (
        <Footer>
            <SocialMedia>
                <Navigation>
                    <FooterComponent href="/">Home</FooterComponent>
                    {/* <FooterComponent href="/technology">Tecnologias</FooterComponent>
                    <FooterComponent href="/team">Nossa equipe</FooterComponent> */}
                    <FooterComponent href="/work">Trabalhe conosco</FooterComponent>
                    <FooterComponent href="/contact">Contato</FooterComponent>
                </Navigation>
                <ContainerImagesSocial>
                    {RenderSocial(instagram, constants.urlInstagram)}
                    {RenderSocial(facebook, constants.urlFacebook)}
                    {RenderSocial(linkedin, constants.urlLinkedin)}
                    {RenderSocial(whatsapp, constants.urlWhatsApp)}
                </ContainerImagesSocial>
                <TitleCopyright>
                    Copyright © 2023 Dotcoding. Todos os direitos reservados.
                </TitleCopyright>
            </SocialMedia>
        </Footer>
    );
};

export default FooterPage;
