// libraries
import styled from 'styled-components';

// js
import { ContainerInterface, TitleInterface, SubTitleInterface } from './cardInterfaces';
import colors from '../../styles/colors';
import consts from '../../utils/consts';

// styles
export const Container = styled.div<ContainerInterface>`
    flex: 1;
    max-width: 90vw;
    flex-direction: column;
    border-style: ${(props) => (props.borderWidth ? 'solid' : 'none')};
    border-width: ${(props) => (props.borderWidth ? '1px' : 'medium')};
    border-color: ${(props) => (props.borderWidth ? colors.basic.mediumGray : colors.basic.white)};
    border-radius: ${(props) => (props.borderWidth ? '7px' : '0px')};
    align-items: center;
    justify-content: center;
    margin: 0px 30px 30px;

    /* media query */
    @media (max-width: 400px) {
        /* flex: 2; */
    }
`;

export const Image = styled.img`
    width: 100px;
    height: 90px;
    object-fit: contain;
    border: 3px solid #421C46;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;

    /* media query */
    @media (max-width: 1024px) {
        width: 100px;
        height: 90px;
    }
`;

export const Title = styled.h1<TitleInterface>`
    margin-top: 30px;
    color: ${(props) => props.colorTitle || colors.basic.black};
    font-size: ${(props) => `${props.fontSizeTitle || 1.5}rem`};
    text-align: center;
    font-weight: ${(props) => props.fontWeightTitle || consts.getFontWeight('Lato-Bold')};
    font-family: 'Quicksand', sans-serif;

    /* media query */
    @media (max-width: 1280px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        margin-top: 50px;
    }
`;

export const SubTitle = styled.h1<SubTitleInterface>`
    color: ${(props) => props.colorSubTitle || colors.basic.black};
    font-size: ${(props) => `${props.fontSizeSubTitle || 1}rem`};
    font-weight: ${(props) => props.fontWeightSubTitle || consts.getFontWeight('Lato-Regular')};
    font-family: 'Quicksand', sans-serif;
    /* height: 100px; */
    /* background: blue; */
    display: flex;
    margin: 0px 100px;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    text-align: center;

    /* media query */
    @media(max-width: 1280px){
        font-size: 18px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-top: 20px;
    }
    @media(max-width: 1024px){
        font-size: ${(props) => `${(props?.fontSizeSubTitle || 1) * 0.9}rem`};
        padding: 0px;
        margin: 10px 0px;
    }
    @media(max-width: 400px){
        font-size: ${(props) => `${(props?.fontSizeSubTitle || 1) * 0.7}rem`};
        max-width: 420px;
    }
`;

export const Point = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 71px;
    font-size: 64px;
    line-height: 80px;
    margin-bottom: 100px;

    @media (max-width: 1024px) {
        margin-bottom: 0px;
        width: 71px;
        height: 16px;
        text-align: center;
        transform: rotate(-90deg);
        background: pink;
    }
`;
