/* eslint-disable max-len */
// libraries
import React, { useState, useEffect } from 'react';

// js
import {
    AreaImage,
    AreaText,
    BodyItens,
    Container,
    ContainerAreaTextAre,
    ContainerAreImage,
    ContainerAreText,
    ContainerCards,
    ContainerCompany,
    ContainerInfo,
    ContainerItensDiferential,
    ContainerNumbers,
    ContainerRocket,
    ContainerRowCards,
    ContainerTitlePage,
    DivAreaDiferential,
    DivBackgroundItens,
    DivBlind,
    DivBorderImage,
    DotNumbers,
    Home,
    ImageItem,
    IntemTitle,
    ItemSeparation,
    OurDifferences,
    Solutions,
    TextCompany,
    // TextNumber,
    // TextNumberRight,
    TextSubTitle,
    TextTitle,
    TitleHome,
    TitlePage,
    WhoAre,
    ContainerAreImageOne,
    ContainerAreTextTitle,
    Point,
    ContainerItensDiferentialItem,
    DivAreaText,
    ContainerAreaItens,
    GapItem,
    CardNumber,
    TitleNumber,
    SubTitleNumber,
    Number,
} from './homeStyles';
import colors from '../../styles/colors';

// images
import database from '../../assets/images/database.png';
import monitor from '../../assets/images/monitor.png';
import shoppingCart from '../../assets/images/shoppingCart.png';
import smartphone from '../../assets/images/smartphone.png';
import expand1 from '../../assets/images/expand1.png';
import headset1 from '../../assets/images/headset1.png';
import ideia1 from '../../assets/images/ideia1.png';
import flash1 from '../../assets/images/flash1.png';
import grupImage from '../../assets/images/grupImage.png';
import imageThrePointes from '../../assets/images/imageThrePointes.png';

// components
import Card from '../../components/Card/CardComponent';
import Menu from '../../components/Menu/MenuComponent';
import Footer from '../../components/Footer/FooterComponent';

const HomePage: React.FC = () => {
    // constants
    const finalDeliveredProjects = 76;
    const finalDominatedTechnologies = 11;
    const finalProfessionals = 7;
    const finalCustomersServed = 26;
    let scrollEnabled = true;

    // states
    const [scrollY, setScrollY] = useState(0);
    const [deliveredProjects, setDeliveredProjects] = useState(0);
    const [dominatedTechnologies, setDominatedTechnologies] = useState(0);
    const [professionals, setProfessionals] = useState(0);
    const [customersServed, setCustomersServed] = useState(0);

    // conditions
    const resizeScreenSolution = (window.innerHeight * 2) + 500;
    const resizeScreenDotNumbers = (window.innerHeight * 3) + 400;
    const resizeScreenOurDifferences = (window.innerHeight * 4) + 400;
    const conditionAnimationSolutions = scrollY >= resizeScreenSolution;
    const conditionAnimationOurDifferences = scrollY >= resizeScreenOurDifferences;

    // use effects
    useEffect(() => {
        const changedScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            setScrollY(scrollPosition);
            if (scrollPosition >= resizeScreenDotNumbers && scrollEnabled) {
                scrollEnabled = false;
                setTimeout(() => {
                    setDeliveredProjects(1);
                    setDominatedTechnologies(1);
                    setProfessionals(1);
                    setCustomersServed(1);
                }, 500);
            }
            if (scrollPosition < resizeScreenDotNumbers && !scrollEnabled) {
                scrollEnabled = true;
                setDeliveredProjects(0);
                setDominatedTechnologies(0);
                setProfessionals(0);
                setCustomersServed(0);
            }
        };
        document.addEventListener('scroll', changedScroll);
    }, []);

    useEffect(() => {
        if (deliveredProjects > 0 && deliveredProjects < finalDeliveredProjects) {
            setTimeout(() => {
                setDeliveredProjects(deliveredProjects + 1);
            }, 50);
        }
        if (dominatedTechnologies > 0 && dominatedTechnologies < finalDominatedTechnologies) {
            setTimeout(() => {
                setDominatedTechnologies(dominatedTechnologies + 1);
            }, 50);
        }
        if (professionals > 0 && professionals < finalProfessionals) {
            setTimeout(() => {
                setProfessionals(professionals + 1);
            }, 50);
        }
        if (customersServed > 0 && customersServed < finalCustomersServed) {
            setTimeout(() => {
                setCustomersServed(customersServed + 1);
            }, 50);
        }
    }, [deliveredProjects, dominatedTechnologies, professionals, customersServed]);

    // renders
    function RenderCardNumbers(title: string, subTitle: string) {
        return (
            <CardNumber>
                <TitleNumber>{title}</TitleNumber>
                <SubTitleNumber>{subTitle}</SubTitleNumber>
            </CardNumber>
        );
    }

    // renderização dos cards esquerda
    function RenderCardsLeft(title: string, subTitle: string, numberCard: string, image: string) {
        return (
            <>
                <ItemSeparation src={imageThrePointes} />
                <DivAreaDiferential scrollY={conditionAnimationOurDifferences}>
                    <ContainerItensDiferential>
                        <AreaImage>
                            <Number>{numberCard}</Number>
                            <DivBorderImage>
                                {/* <TextNumber>{numberCard}</TextNumber> */}
                                <ImageItem src={image} />
                            </DivBorderImage>
                        </AreaImage>
                        <AreaText>
                            <TextTitle>{title}</TextTitle>
                            <TextSubTitle>{subTitle}</TextSubTitle>
                        </AreaText>
                    </ContainerItensDiferential>
                </DivAreaDiferential>

            </>
        );
    }

    // renderização dos cards a direita
    function RenderCardsRight(title: string, subTitle: string, numberCard: string, image: string) {
        return (
            <>
                <ItemSeparation src={imageThrePointes} />
                <DivAreaDiferential scrollY={conditionAnimationOurDifferences}>
                    <ContainerItensDiferentialItem>
                        <AreaText>
                            <TextTitle>{title}</TextTitle>
                            <TextSubTitle>{subTitle}</TextSubTitle>
                        </AreaText>
                        <AreaImage>
                            <Number>{numberCard}</Number>
                            <DivBorderImage>
                                {/* <TextNumberRight>{numberCard}</TextNumberRight> */}
                                <ImageItem src={image} />
                            </DivBorderImage>
                        </AreaImage>
                    </ContainerItensDiferentialItem>
                </DivAreaDiferential>

            </>
        );
    }

    // main
    return (
        <Container>
            <Home>
                <ContainerRocket>
                    <Menu />
                    <ContainerInfo>
                        <TitleHome
                            fontWeight={700}
                            fontSize={40}
                        >
                            Impulsione!
                        </TitleHome>
                        <TitleHome
                            fontWeight={400}
                            fontSize={24}
                            marginTop={50}
                        >
                            Nós mudamos sua empresa para o mundo digital.
                            Com o uso de tecnologia, desenvolvemos softwares de
                            alto desempenho para o seu negócio.
                        </TitleHome>
                    </ContainerInfo>
                </ContainerRocket>
            </Home>
            <WhoAre>
                <ContainerAreaTextAre>
                    <DivAreaText>
                        <ContainerAreTextTitle
                            fontWeight={700}
                            fontSize={36}
                            color={colors.theme.purpleDark}
                        >
                            QUEM SOMOS
                        </ContainerAreTextTitle>
                        <ContainerAreaItens>
                            <ContainerAreImageOne src={grupImage} />
                            <ContainerAreText
                                display="flex"
                                fontWeight={400}
                                fontSize={18}
                                color={colors.basic.black}
                            >
                                A Dotcoding é uma empresa de tecnologia, fundada em 2020,
                                que tem como objetivo agregar valor aos negócios de seus clientes,
                                através da constante evolução e busca por inovação, fornecendo soluções personalizadas que se adequam a necessidade de cada empresa, além de serviços
                                especializados e de qualidade.
                                <GapItem />
                                Idealizada com o propósito de desenvolver aplicativos móveis, paineis administrativos,
                                sites e servidores, ajudando empresas de diversos setores a tirar seus planos do papel e
                                possibilitando a utilização de tecnologia como forma de potencializar seus empreendimentos.
                                <GapItem />
                                Visamos também a qualificação e o aperfeiçoamento dos
                                profissionais, buscando dessa forma, suprir as necessidades do mercado, bem como
                                capacita-los para responder à altura das responsabilidades que lhes são confiadas.
                            </ContainerAreText>
                            <ContainerAreImage src={grupImage} />
                        </ContainerAreaItens>
                    </DivAreaText>
                </ContainerAreaTextAre>
            </WhoAre>
            <ContainerCompany backGroundImage>
                <ContainerCompany>
                    <TextCompany>
                        A empresa que vai mudar sua forma de trabalhar.
                    </TextCompany>
                </ContainerCompany>
            </ContainerCompany>
            <Solutions>
                <ContainerTitlePage>
                    <TitlePage
                        color={colors.basic.black}
                        paddingRight={5}
                    >
                        Soluções
                    </TitlePage>
                    <TitlePage
                        color={colors.theme.purpleDark}
                        paddingLeft={5}
                    >
                        personalizadas
                    </TitlePage>
                </ContainerTitlePage>
                <ContainerCards scrollY={conditionAnimationSolutions}>
                    <ContainerRowCards>
                        <Card
                            sourceImage={monitor}
                            title="Software sob medida"
                            subtitle="Soluções para otimização e transformação digital de sistemas e processos para pequenas, médias e grandes empresas."
                            fontSizeTitle={1.2}
                        />
                        <Card
                            sourceImage={smartphone}
                            title="Apps Mobile"
                            subtitle="Desenvolvimento de aplicações para sistema iOS e Android para uso nativo ou multiplataforma."
                            fontSizeTitle={1.2}
                        />
                    </ContainerRowCards>
                    <ContainerRowCards>
                        <Card
                            sourceImage={database}
                            title="Refactoring"
                            subtitle="Reescrita de softwares legados com atualização de arquitetura, usabilidade e melhorias de processos. "
                            fontSizeTitle={1.2}
                        />
                        <Card
                            sourceImage={shoppingCart}
                            title="E-commerce"
                            subtitle="Realizamos implementação, customização e integração de lojas e marketplaces. Nossa ferramenta é utilizada pelos maiores players do mercado."
                            fontSizeTitle={1.2}
                        />
                    </ContainerRowCards>
                </ContainerCards>
            </Solutions>
            <DotNumbers>
                <DivBackgroundItens>
                    <IntemTitle>NOSSAS ESTATÍSTICAS</IntemTitle>
                    <BodyItens>
                        <ContainerNumbers>
                            {RenderCardNumbers(String(deliveredProjects), 'PROJETOS ENTREGUES')}
                            <Point>.</Point>
                            {RenderCardNumbers(String(dominatedTechnologies), 'TECNOLOGIAS DOMINADAS')}
                            <Point>.</Point>
                            {RenderCardNumbers(String(professionals), 'PROFISSIONAIS')}
                            <Point>.</Point>
                            {RenderCardNumbers(String(customersServed), 'CLIENTES')}
                        </ContainerNumbers>
                    </BodyItens>
                </DivBackgroundItens>
            </DotNumbers>
            <OurDifferences>
                <ContainerTitlePage>
                    <TitlePage
                        color={colors.basic.black}
                        paddingRight={5}
                    >
                        Nossos
                    </TitlePage>
                    <TitlePage
                        color={colors.theme.purpleDark}
                        paddingLeft={5}
                    >
                        diferenciais
                    </TitlePage>
                </ContainerTitlePage>
                {RenderCardsLeft(
                    'Agilidade no desenvolvimento',
                    'Sabemos o quanto é importante acertar o timing de um projeto de software, por isso trabalhamos com metodologias ágeis e times multidisciplinares.',
                    '01',
                    `${flash1}`,
                )}
                {RenderCardsRight(
                    'Exeperiência e Conhecimento',
                    'Ao longo de nossa história trabalhamos com diversos segmentos e temos know-how necessário para atender os mais variados projetos.',
                    '02',
                    `${ideia1}`,
                )}
                {RenderCardsLeft(
                    'Qualidade no atendimento',
                    'Time capacitado com dedicação 100% ao negócio, envolvendo pessoas e tecnologia. Por isso, a importância do fator humano e do relacionamento.',
                    '03',
                    `${headset1}`,
                )}
                {RenderCardsRight(
                    'Flexibilidade de verdade',
                    'Capacidade de prover recursos para a produção do seu software, em qualquer etapa do ciclo de vida de desenvolvimento e em diversos modelos de contratação.',
                    '04',
                    `${expand1}`,
                )}
            </OurDifferences>
            <DivBlind height={40} />
            <Footer />
        </Container>
    );
};

export default HomePage;
